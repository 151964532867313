import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./index.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import load from "../../../assets/images/icons/Spinner.gif";
import Moment from "moment";
import ConditionFilter from "../../../components/Filters/condition-filter";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import ExportIcon from "../../../assets/images/icons/export.svg";
import axios from "axios";
import { toast } from "react-toastify";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

let rows: any = [];
let columns: GridColDef[] = [];

function TargetingTable(props) {
  let apiEndPoint =
    "https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/" +
    props.tabName +
    "/GetAll";

  let pageload = true;
  let currPage = 1;
  let dataLength = 50;
  let exporturl = "";
  const [selectedRowid, setSelectedRows] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [marketplace, setMarketplace] = useState("");
  const [statusTarget, setStatus] = useState("");
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [filterDateRange, setFilterDateRange] = useState<any[]>([]);
  const [matchstate, setMatchstate] = useState("");
  let filterData: any = [];
  let Condition: any = [];

  const [globalFilterFromData, setGlobalFilterFromData] = useState<any[]>([]);
  const [searchKeyFilter, setSearchKeyFilter] = useState("");
  const [datainput, setBisvalue] = useState("");
  const [targeted, setTargetedValue] = useState("");
  const [selctedunit, setSelectedunit] = useState("");
  const [selectedTab, setSelectedTab] = useState("Status");
  const [download, setDownload] = useState<any>();
  // aaa
  const [columFiltervalue, setColumFiltervalue] = useState<any[]>([]);
  const [dropdownDataFilter, SetDropdownDataFilter] = useState<any[]>([]);
  const [spinner, setSpinner] = useState(false);
  const [searchKeyValue, setSearchKeyValue] = useState<any[]>([]);
  const [dropdownDatas, setDropdownDatas] = useState<any[]>([]);
  const [checked, setChecked] = useState(false);
  const [pageLength, setPageLength] = useState(50);
  const [filter, setFilter] = useState<string[]>([]);
  const [filterShow, setFilterShow] = useState(false);
  const [sliceIndex, setSliceIndex] = useState<any>();
  const [statusModal, setStatusModal] = useState(false);
  const [bidModal, setBidModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Select Status");
  const [bidValue, setBidValue] = useState("");
  const [bidValue1, setBidValue1] = useState("Select Bid");
  const [bidValue2, setBidValue2] = useState("Select Unit");
  const [selectedStatusRow, setSelectedStatusRow] = useState<string[]>([]);

  let searchKey = "";
  useEffect(() => {
    setMetaData(props.metaData);
  }, [props.metaData, metaData]);

  useEffect(() => {
    if (props.tabName && pageload) {
      // fetchData();
      pageload = false;
    }
  }, []);

  useEffect(() => {
    setApiLoading(true);
    if (props.condition && props.selectedProfiles && props.filterDateRange) {
      if (filterDateRange !== props.filterDateRange) {
        setFilterDateRange(props.filterDateRange);
      }
      setFilterShow(false);
      columns = [];
      fetchData();
    }
  }, [props.condition, props.selectedProfiles, props.filterDateRange]);

  const fetchData = async () => {
    setApiLoading(true);
    let apiEndPoint;

    if (props.selectedProfiles === "SP") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/${props.tabName}/GetAll`;
    } else if (props.selectedProfiles === "SB") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SBCampaignManager/${props.tabName}/GetAll`;
    } else if (props.selectedProfiles === "SD") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SDCampaignManager/${props.tabName}/All`;
    }

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = apiEndPoint;
    let advancedFilters: any = [];
    let advancedFilterDateRange: any = {};

    if (filterData.length > 0) {
      advancedFilters = filterData;
    }
    if (props.filterDateRange.length > 0) {
      advancedFilterDateRange = {
        dateRange: "Custom",
        startDate:
          Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
        endDate:
          Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
      };
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        profileId: Number(props.condition),
        pageNumber: currPage,
        pageSize: dataLength,
        globalFilters: {
          searchText: searchKey,
          advancedFilters: advancedFilters,
          dateRanges: advancedFilterDateRange,
        },
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      let result = responceData.result;
      setDownload(result.data[0])
      setTotalRow(result.filteredCount);
      setPerPage(result.perPage);
      setActivePage(result.currPage);
      setLastPage(result.lastPage);

      let headers = responceData.result.headers;
      setDropdownDatas(headers);
      SetDropdownDataFilter(headers);
      setApiLoading(false);

      if (columns.length < 1) {
        for (let i = 0; headers.length > i; i++) {
          if (headers[i]["keyName"] === "status") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i className={"status " + params.row.status}></i>
              ),
              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "campaignStatus") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i className={"status " + params.row.campaignStatus}></i>
              ),
              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "adGroupStatus") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i className={"status " + params.row.adGroupStatus}></i>
              ),
              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "adKeywordStatus") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i className={"status " + params.row.adKeywordStatus}></i>
              ),
              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "marketplace") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i className={"flag-" + params.row.marketplace}></i>
              ),
              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "campaignName") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 140,

              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "keyword") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 250,

              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "adGroupName") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 140,

              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "targeting") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 140,

              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "targetingText") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 250,

              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "matchType") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 120,

              editable: true,
              description: headers[i]["fullName"],
            });
          } else {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 100,
              description: headers[i]["fullName"],
            });
          }
        }
      }
      rows = responceData.result.data;
      setApiLoading(false);
    } catch (error) {
      toast("Something went wrong");
      setApiLoading(false);
      rows = [];
      console.error("Error fetching data:", error);
    }
  };

  const applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    setPageLength(e.target.value);
    dataLength = parseInt(e.target.value);
    filterData = globalFilterFromData;
    searchKey = searchKeyFilter;
    fetchData();
  };

  useEffect(() => {
    if (filter.length > 0) {
      setFilterShow(true);
    } else {
      setFilterShow(false);
    }
  }, [filter]);

  const handleCallback = (childData) => {
    console.log(childData);
    if (childData.length > 0) {
      const newFilters = childData.map((data) => {
        const name = data.conditions[0].columnName;
        const operator = data.conditions[0].operator;
        const value = data.conditions[0].value;
        const secondValue = data.conditions[0].secondValue;
        let newFilter;
        if (name === "Status") {
          const updateValue =
            value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
          newFilter = `${name} = ${updateValue}`;
        } else if (operator === "Between") {
          if (name === "KeywordBid") {
            newFilter = `Bid Between ${value} , ${secondValue}`;
          } else if (name === "ClickThroughRate") {
            newFilter = `CTR Between ${value} , ${secondValue}`;
          } else if (name === "CostPerClick") {
            newFilter = `CPC Between ${value} , ${secondValue}`;
          } else if (name === "Cost") {
            newFilter = `Spend ${value} , ${secondValue}`;
          } else if (name === "TargetingText") {
            newFilter = `Targeting ${value} , ${secondValue}`;
          } else if (name === "AcosClicks14d") {
            newFilter = `ACOS ${value} , ${secondValue}`;
          } else if (name === "RoasClicks14d") {
            newFilter = `ROAS ${value} , ${secondValue}`;
          } else if (name === "Sales14d") {
            newFilter = `Sales ${value} , ${secondValue}`;
          } else if (name === "Purchases14d") {
            newFilter = `Orders ${value} , ${secondValue}`;
          } else if (name === "Purchases") {
            newFilter = `Orders ${value} , ${secondValue}`;
          } else {
            newFilter = `${name} Between ${value} , ${secondValue}`;
          }
        } else {
          if (name === "KeywordBid") {
            newFilter = `Bid ${operator} ${value}`;
          } else if (name === "ClickThroughRate") {
            newFilter = `CTR ${operator} ${value}`;
          } else if (name === "CostPerClick") {
            newFilter = `CPC ${operator} ${value}`;
          } else if (name === "Cost") {
            newFilter = `Spend ${operator} ${value}`;
          } else if (name === "TargetingText") {
            newFilter = `Targeting ${operator} ${value}`;
          } else if (name === "AcosClicks14d") {
            newFilter = `ACOS ${operator} ${value}`;
          } else if (name === "RoasClicks14d") {
            newFilter = `ROAS ${operator} ${value}`;
          } else if (name === "Sales14d") {
            newFilter = `Sales ${operator} ${value}`;
          } else if (name === "Purchases14d") {
            newFilter = `Orders ${operator} ${value}`;
          } else if (name === "Purchases") {
            newFilter = `Orders ${operator} ${value}`;
          } else {
            newFilter = `${name} ${operator} ${value}`;
          }
        }
        return newFilter;
      });
      console.log(newFilters);
      setFilter(newFilters);
    } else {
      setFilter([]);
    }

    searchKey = searchKeyFilter;
    setGlobalFilterFromData(childData);
    filterData = childData;
    dataLength = Number(pageLength);
    fetchData();
  };

  const cancelFilter = (i) => {
    const newFilter = filter.slice(0, i);
    setFilter(newFilter);
    setSliceIndex(i);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      filterData = globalFilterFromData;
      searchKey = event.target.value;
      dataLength = Number(pageLength);
      setSearchKeyFilter(event.target.value);
      fetchData();
      event.preventDefault();
    }
  };
  const cancelDropdown = () => {
    const eleRm: any = document.getElementsByClassName("dropdown-menu");
    for (let i = 0; i < eleRm.length; i++) {
      eleRm[i].classList.remove("show");
    }
  };

  // aaa hide data
  const hiddenColumns = columFiltervalue;
  // console.log("hide column : " + hiddenColumns);

  const handleChange = (event, value: number) => {
    currPage = value;
    filterData = globalFilterFromData;
    searchKey = searchKeyFilter;
    dataLength = Number(pageLength);
    fetchData();
  };

  let userToken = localStorage.getItem("userToken");
  let AuthToken = "Bearer " + userToken;

  const handleExportData = async () => {
    setSpinner(true);

    try {
      let userToken = localStorage.getItem("userToken");
      if (!userToken) {
        throw new Error("User token not found");
      }

      let AuthToken = "Bearer " + userToken;
      let advancedFilters: any = [];
      let advancedFilterDateRange: any = {};

      if (globalFilterFromData.length > 0) {
        advancedFilters = globalFilterFromData;
      }

      if (props.filterDateRange.length > 0) {
        advancedFilterDateRange = {
          dateRange: "Custom",
          startDate:
            Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
          endDate:
            Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
        };
      }

      let apiEndPoint = "";

      switch (props.selectedProfiles) {
        case "SP":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/${props.tabName}/Export`;
          break;
        case "SB":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SBCampaignManager/${props.tabName}/Export`;
          break;
        case "SD":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SDCampaignManager/${props.tabName}/Export`;
          break;
        default:
          throw new Error("Invalid profile selected");
      }

      const response = await fetch(apiEndPoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          profileId: Number(props.condition),
          pageNumber: currPage,
          pageSize: dataLength,
          globalFilters: {
            searchText: searchKeyFilter,
            advancedFilters: advancedFilters,
            dateRanges: advancedFilterDateRange,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to export data");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      let profileDescription;
      switch (props.selectedProfiles) {
        case "SP":
          profileDescription = "Sponsored Product";
          break;
        case "SB":
          profileDescription = "Sponsored Brand";
          break;
        case "SD":
          profileDescription = "Sponsored Display";
          break;
        default:
          throw new Error("Invalid profile selected");
      }
      const storedLabel = localStorage.getItem("selectedAccountLabel");
      const country = localStorage.getItem("Country");

      console.log("Stored Label:", storedLabel);

      a.download = `${storedLabel}${country}_${profileDescription}_${
        props.tabName
      }_${advancedFilterDateRange.startDate.slice(
        0,
        10
      )}-${advancedFilterDateRange.endDate.slice(0, 10)}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // Handle success
      console.log("Export successful");
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      toast("Export failed");
      console.error("Error exporting data:", error);
    }
  };

  const ChangeStatus = () => {
    const Data = {
      type: "Target",
      reportType: props.selectedProfiles,
      operation: "Status",
      value: {
        Status: selectedStatus,
        Target: selectedStatusRow,
      },
      identifiers: null,
    };
    const formattedObject = {
      ...Data,
      value: JSON.stringify(Data.value),
    };
    console.log(formattedObject);
    if (formattedObject) {
      setSelectedStatusRow([]);
      const Status = async () => {
        let userToken = localStorage.getItem("userToken");
        let AuthToken = "Bearer " + userToken;
        let url =
          "https://adsautomationapi.agilensmartservices.com/BulkUpdates/BulkRequest";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify(formattedObject),
        };
        try {
          const response = await fetch(url, requestOptions);
          const responceData = await response.json();
          console.log(responceData);
          if (responceData.success === true) {
            toast(responceData.message);
            fetchData();
          } else {
            toast(responceData.message);
          }
          setSelectedStatus("Select Status");
          setStatusModal(false);
        } catch (error) {
          setSelectedStatus("Select Status");
          console.log(error);
          setStatusModal(false);
        }
      };
      Status();
    }
  };
  const ChangeBid = () => {
    const Data = {
      type: "Target",
      reportType: props.selectedProfiles,
      operation: "Default Bid",
      value: {
        DefaultBid: {
          ActionType: bidValue1,
          BidValue: bidValue,
          Unit: bidValue2,
        },
        Target: selectedStatusRow,
      },
      identifiers: null,
    };
    const formattedObject = {
      ...Data,
      value: JSON.stringify(Data.value),
    };
    console.log(formattedObject);

    if (formattedObject) {
      setSelectedStatusRow([]);
      const Bid = async () => {
        let userToken = localStorage.getItem("userToken");
        let AuthToken = "Bearer " + userToken;
        let url =
          "https://adsautomationapi.agilensmartservices.com/BulkUpdates/BulkRequest";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify(formattedObject),
        };
        try {
          const response = await fetch(url, requestOptions);
          const responceData = await response.json();
          console.log(responceData);
          if (responceData.success === true) {
            toast(responceData.message);
            fetchData();
          } else {
            toast(responceData.message);
          }
          setBidValue("");
          setBidValue1("Select Bid");
          setBidValue2("Select Unit");
          setBidModal(false);
        } catch (error) {
          console.log(error);
          setBidValue("");
          setBidValue1("Select Bid");
          setBidValue2("Select Unit");
          setBidModal(false);
        }
      };
      Bid();
    }
  };

  const handleStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const handleBid = (e) => {
    setBidValue(e.target.value);
  };
  const handleBid1 = (e) => {
    setBidValue1(e.target.value);
  };
  const handleBid2 = (e) => {
    setBidValue2(e.target.value);
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <Row className="mt-2 mb-2">
        {/* <Col>
            <div className="custom-dropdown-container">
              <div className="dropdownContent">
                <p
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Bulk Operation
                  <i
                    className="fa fa-angle-down down-arrow-right"
                    aria-hidden="true"
                  ></i>
                  <i
                    className="fa fa-angle-up up-arrow-right"
                    aria-hidden="true"
                  ></i>
                </p>
                <ul
                  className="dropdown-menu shadow rounded"
                  style={{ minWidth: "136px" }}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li onClick={() => setStatusModal(true)}>Status</li>
                  <li onClick={() => setBidModal(true)}>Bid</li>
                </ul>
              </div>
            </div>
          </Col> */}

        {statusModal && (
          <Modal
            show={statusModal}
            onHide={() => setStatusModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Status
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <select
                  className="form-select"
                  onChange={handleStatus}
                  value={selectedStatus}
                >
                  <option disabled>Select Status</option>
                  <option value="Enabled">Enabled</option>
                  <option value="Paused">Paused</option>
                </select>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <div>
                  <Button
                    className="secondary"
                    onClick={() => setStatusModal(false)}
                  >
                    Close
                  </Button>
                </div>
                <div>
                  <Button
                    className="btn btn-primary add-rules-btn "
                    onClick={ChangeStatus}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {bidModal && (
          <Modal
            show={bidModal}
            onHide={() => setBidModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Bid</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <select
                  className="form-select"
                  onChange={handleBid1}
                  value={bidValue1}
                >
                  <option disabled>Select Bid</option>
                  <option value="Increase bid by">Increase bid by</option>
                  <option value="Lower bid by">Lower bid by</option>
                  <option value="Set bid to">Set bid to</option>
                </select>
              </div>
              <div className="mt-3">
                <input
                  className="form-control"
                  value={bidValue}
                  onChange={handleBid}
                  style={{ height: "34.85px" }}
                  placeholder="Enter Bid Value"
                ></input>
              </div>
              <div className="mt-3">
                <select
                  className="form-select"
                  onChange={handleBid2}
                  value={bidValue2}
                >
                  <option disabled>Select Unit</option>
                  <option value="%">%</option>
                  <option value="$">$</option>
                </select>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <div>
                  <Button
                    className="secondary"
                    onClick={() => setBidModal(false)}
                  >
                    Close
                  </Button>
                </div>
                <div>
                  <Button
                    className="btn btn-primary add-rules-btn "
                    onClick={ChangeBid}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

        <Col>
          <div className="filter-container">
            <Row>
              <Col md={3} className="padding-lr-10"></Col>
              <Col md={9}>
                <div>
                  <form>
                    <div className="search-filter-container">
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        placeholder="Search"
                        id="globalSearch"
                        name="globalSearch"
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </form>
                </div>
                <div className="filter-item filter-link-container dropdownContent">
                  <p
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    <i>
                      <img src={FilterIcon} alt="filter icon" />
                    </i>
                    <span className="me-2">Filter</span>
                    <i
                      className="fa fa-angle-down down-arrow-right me-1"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <div
                    className="dropdown-menu dropdown-menu-lg-end"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <ConditionFilter
                      parentCallback={handleCallback}
                      dropdownData={dropdownDatas}
                      metaData={metaData}
                      sliceIndex={sliceIndex}
                    />
                  </div>
                </div>

                {!spinner ? (
                  <div
                    className="filter-item export-link-container"
                    onClick={handleExportData}
                  >
                    <p>
                      <i>
                        <img src={ExportIcon} alt="filter icon" />
                      </i>
                      <span>Export</span>
                    </p>
                  </div>
                ) : (
                  <div className="filter-item export-link-container">
                    <img src={load} height={40} width={40} alt="spinner" />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {!apiLoading ? (
        <div>
          {filterShow && (
            <div className="filter-box d-flex  align-items-center">
              <FilterListOutlinedIcon fontSize="medium" />
              {filter.map((item, index) => (
                <div
                  className="filter-content ms-3 d-flex align-items-center justify-content-between"
                  key={index}
                >
                  {item}
                  <CancelOutlinedIcon
                    className="clear-filter ms-2"
                    fontSize="small"
                    onClick={() => cancelFilter(index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div style={{ height: "calc(75vh - 120px)", width: "100%" }}>
            <DataGrid
              rows={rows}
              //columns={columns}
              // aaa
              columns={columns.filter(
                (col) => !hiddenColumns.includes(col.field)
              )}
              // checkboxSelection={props.checkBox}
              onRowSelectionModelChange={(id) => {
                const selectedIDs = new Set(id);
                if (props.selectedProfiles === "SD") {
                  const selectedRows = rows
                    .filter((row: any) => id.includes(row.id))
                    .map((row: any) => ({
                      TargetId: row.targetingId,
                      Type: row.matchType,
                      ProfileId: props.condition,
                    }));
                  setSelectedStatusRow(selectedRows);
                  console.log(selectedRows);
                } else {
                  const selectedRows = rows
                    .filter((row: any) => id.includes(row.id))
                    .map((row: any) => ({
                      TargetId: row.keywordId,
                      Type: row.matchType,
                      ProfileId: props.condition,
                    }));
                  setSelectedStatusRow(selectedRows);
                  console.log(selectedRows);
                }

                console.log("idssss" + id);
                setSelectedRows(id);
                console.log("selected" + selectedRowid);
              }}
              hideFooter={true}
              rowHeight={40}
            />
            <div className="custom-table-footer">
              <Row>
                <Col md={5}>
                  <form className="table-footer-left">
                    <span>Show </span>
                    <label>
                      <select
                        className="form-select"
                        defaultValue={perPage}
                        onChange={(event) => applyDataLength(event)}
                      >
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </label>
                    <span> of {totalRow} total entries</span>
                  </form>
                </Col>
                <Col md={7}>
                  <div className="table-footer-right">
                    <Stack spacing={2}>
                      <Pagination
                        count={lastPage}
                        page={activePage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                      />
                    </Stack>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress className="loading" style={{ margin: "auto" }} />
          </div>
        </div>
      )}
    </div>
  );
}
export default TargetingTable;
