/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./index.css";
import { Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Moment from "moment";
import ConditionFilter from "../../../components/Filters/condition-filter";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import ExportIcon from "../../../assets/images/icons/export.svg";
import load from "../../../assets/images/icons/Spinner.gif";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

let rows: any = [];
let columns: GridColDef[] = [];

function MarketplaceTable(props) {
  let apiEndPoint =
    "https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/" +
    props.tabName +
    "/GetAll";

  let pageload = true;
  let currPage = 1;
  let dataLength = 50;
  const [metaData, setMetaData] = useState<any>([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [filterDateRange, setFilterDateRange] = useState<any[]>([]);
  const [spinner, setSpinner] = useState(false);

  // aaa
  const [dropdownDatas, setDropdownDatas] = useState<any[]>([]);
  const [dropdownDataFilter, SetDropdownDataFilter] = useState<any[]>([]);
  const [columFiltervalue, setColumFiltervalue] = useState<any[]>([]);
  let filterData: any = [];
  let Condition: any = [];
  let searchKey = "";
  let exporturl = "";
  const [globalFilterFromData, setGlobalFilterFromData] = useState<any[]>([]);
  const [searchKeyFilter, setSearchKeyFilter] = useState("");
  const [pageLength, setPageLength] = useState(50);
  const [rows, setRows] = useState<any[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  const [filterShow, setFilterShow] = useState(false);
  const [sliceIndex, setSliceIndex] = useState<any>();
  const [download, setDownload] = useState<any>();
  console.log(props);
  useEffect(() => {
    setMetaData(props.metaData);
  }, [props.metaData, metaData]);

  useEffect(() => {
    setApiLoading(true);
    if (props.condition && props.selectedProfiles && props.filterDateRange) {
      if (filterDateRange !== props.filterDateRange) {
        setFilterDateRange(props.filterDateRange);
      }
      setFilterShow(false);
      columns = [];
      fetchData();
    }
  }, [props.condition, props.selectedProfiles, props.filterDateRange]);

  useEffect(() => {
    if (props.tabName && pageload) {
      pageload = false;
    }
  }, []);

  const fetchData = async () => {
    setApiLoading(true);
    let apiEndPoint;

    if (props.selectedProfiles === "SP") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/${props.tabName}/GetAll`;
    } else if (props.selectedProfiles === "SB") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SBCampaignManager/${props.tabName}/GetAll`;
    } else if (props.selectedProfiles === "SD") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SDCampaignManager/${props.tabName}/All`;
    }
    console.log(props.selectedProfiles);
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = apiEndPoint;
    let advancedFilters = [];
    let advancedFilterDateRange = {};

    if (filterData.length > 0) {
      advancedFilters = filterData;
    }

    if (props.filterDateRange.length > 0) {
      advancedFilterDateRange = {
        dateRange: "Custom",
        startDate:
          Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
        endDate:
          Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
      };
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        profileId: Number(props.condition),
        pageNumber: currPage,
        pageSize: dataLength,
        globalFilters: {
          searchText: searchKey,
          advancedFilters: advancedFilters,
          dateRanges: advancedFilterDateRange,
        },
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      let result = responceData.result;
      setDownload(result.data[0]);
      setTotalRow(result.filteredCount);
      setPerPage(result.perPage);
      setActivePage(result.currPage);
      setLastPage(result.lastPage);
      let headers = responceData.result.headers;

      setDropdownDatas(headers);

      SetDropdownDataFilter(headers);
      let filteredRows = responceData.result.data.filter(
        (row) => row.impressions > 0
      );

      if (columns.length < 1) {
        for (let i = 0; headers.length > i; i++) {
          if (headers[i]["keyName"] === "campaignStatus") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i
                  className={"status " + params.row.campaignStatus}
                  style={{ fontSize: "20px" }}
                ></i>
              ),
              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "marketplace") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,
              renderCell: (params) => (
                <i
                  className={"flag-" + params.row.marketplace}
                  style={{ fontSize: "20px" }}
                ></i>
              ),
              description: headers[i]["fullName"],
            });
          } else if (headers[i]["keyName"] === "campaign") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 250,
              description: headers[i]["fullName"],
            });
          } else {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 100,
              flex: 1,
              description: headers[i]["fullName"],
            });
          }
        }
      }

      setRows(filteredRows);
      setApiLoading(false);
    } catch (error) {
      toast("Something went wrong");
      setApiLoading(false);
      setRows([]);
      console.error("Error fetching data:", error);
    }
  };

  const applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    setPageLength(e.target.value);
    dataLength = parseInt(e.target.value);
    filterData = globalFilterFromData;
    searchKey = searchKeyFilter;
    fetchData();
  };

  useEffect(() => {
    if (filter.length > 0) {
      setFilterShow(true);
    } else {
      setFilterShow(false);
    }
  }, [filter]);

  const handleCallback = (childData) => {
    console.log(childData);
    if (childData.length > 0) {
      const newFilters = childData.map((data) => {
        const name = data.conditions[0].columnName;
        const operator = data.conditions[0].operator;
        const value = data.conditions[0].value;
        const secondValue = data.conditions[0].secondValue;
        let newFilter;
        if (name === "Status") {
          const updateValue =
            value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
          newFilter = `${name} = ${updateValue}`;
        } else if (operator === "Between") {
          if (name === "KeywordBid") {
            newFilter = `Bid Between ${value} , ${secondValue}`;
          } else if (name === "ClickThroughRate") {
            newFilter = `CTR Between ${value} , ${secondValue}`;
          } else if (name === "CostPerClick") {
            newFilter = `CPC Between ${value} , ${secondValue}`;
          } else {
            newFilter = `${name} Between ${value} , ${secondValue}`;
          }
        } else {
          if (name === "KeywordBid") {
            newFilter = `Bid ${operator} ${value}`;
          } else if (name === "ClickThroughRate") {
            newFilter = `CTR ${operator} ${value}`;
          } else if (name === "CostPerClick") {
            newFilter = `CPC ${operator} ${value}`;
          } else {
            newFilter = `${name} ${operator} ${value}`;
          }
        }
        return newFilter;
      });
      console.log(newFilters);
      setFilter(newFilters);
    } else {
      setFilter([]);
    }

    searchKey = searchKeyFilter;
    setGlobalFilterFromData(childData);
    filterData = childData;
    dataLength = Number(pageLength);
    fetchData();
  };

  const cancelFilter = (i) => {
    const newFilter = filter.slice(0, i);
    setFilter(newFilter);
    setSliceIndex(i);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      filterData = globalFilterFromData;
      searchKey = event.target.value;
      dataLength = Number(pageLength);
      setSearchKeyFilter(event.target.value);
      fetchData();
      event.preventDefault();
    }
  };

  // aaa hide data
  const hiddenColumns = columFiltervalue;

  const handleChange = (event, value: number) => {
    currPage = value;
    filterData = globalFilterFromData;
    searchKey = searchKeyFilter;
    dataLength = Number(pageLength);
    fetchData();
  };

  // filter
  const filterdData = (e) => {
    console.log("click check button " + e.target.value);
    // aaa
    setColumFiltervalue(e.target.value);
  };

  const handleExportData = async () => {
    setSpinner(true);

    try {
      let userToken = localStorage.getItem("userToken");
      if (!userToken) {
        throw new Error("User token not found");
      }

      let AuthToken = "Bearer " + userToken;
      let advancedFilters: any = [];
      let advancedFilterDateRange: any = {};

      if (globalFilterFromData.length > 0) {
        advancedFilters = globalFilterFromData;
      }

      if (props.filterDateRange.length > 0) {
        advancedFilterDateRange = {
          dateRange: "Custom",
          startDate:
            Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
          endDate:
            Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
        };
      }

      let apiEndPoint = "";

      switch (props.selectedProfiles) {
        case "SP":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/${props.tabName}/Export`;
          break;
        case "SB":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SBCampaignManager/${props.tabName}/Export`;
          break;
        case "SD":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SDCampaignManager/${props.tabName}/Export`;
          break;
        default:
          throw new Error("Invalid profile selected");
      }

      const response = await fetch(apiEndPoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          profileId: Number(props.condition),
          pageNumber: currPage,
          pageSize: dataLength,
          globalFilters: {
            searchText: searchKeyFilter,
            advancedFilters: advancedFilters,
            dateRanges: advancedFilterDateRange,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to export data");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      let profileDescription;
      switch (props.selectedProfiles) {
        case "SP":
          profileDescription = "Sponsored Product";
          break;
        case "SB":
          profileDescription = "Sponsored Brand";
          break;
        case "SD":
          profileDescription = "Sponsored Display";
          break;
        default:
          throw new Error("Invalid profile selected");
      }
      const storedLabel = localStorage.getItem("selectedAccountLabel");
      const country = localStorage.getItem("Country");

      console.log("Stored Label:", storedLabel);

      a.download = `${storedLabel}${country}_${profileDescription}_Summary_${advancedFilterDateRange.startDate.slice(
        0,
        10
      )}-${advancedFilterDateRange.endDate.slice(0, 10)}.csv`;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      console.log("Export successful");
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      toast("Export failed");
      console.error("Error exporting data:", error);
    }
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <Row className="mt-2 mb-2">
        <Col>
          <div className="filter-container">
            <Row>
              <Col md={3} className="padding-lr-10"></Col>
              <Col md={9}>
                <div>
                  <form>
                    <div className="search-filter-container">
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        placeholder="Search"
                        id="globalSearch"
                        name="globalSearch"
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </form>
                </div>
                <div className="filter-item filter-link-container dropdownContent">
                  <p
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    <i>
                      <img src={FilterIcon} alt="filter icon" />
                    </i>
                    <span className="me-2">Filter </span>
                    <i
                      className="fa fa-angle-down down-arrow-right me-1"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <div
                    className="dropdown-menu dropdown-menu-lg-end "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <ConditionFilter
                      parentCallback={handleCallback}
                      sliceIndex={sliceIndex}
                      dropdownData={dropdownDatas}
                      metaData={metaData}
                    />
                  </div>
                </div>
                {!spinner ? (
                  <div
                    className="filter-item export-link-container"
                    onClick={handleExportData}
                  >
                    <p>
                      <i>
                        <img src={ExportIcon} alt="filter icon" />
                      </i>
                      <span>Export</span>
                    </p>
                  </div>
                ) : (
                  <div className="filter-item export-link-container">
                    <img src={load} height={40} width={40} alt="spinner" />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {!apiLoading ? (
        <div>
          {filterShow && (
            <div className="filter-box d-flex  align-items-center">
              <FilterListOutlinedIcon fontSize="medium" />
              {filter.map((item, index) => (
                <div
                  className="filter-content ms-3 d-flex align-items-center justify-content-between"
                  key={index}
                >
                  {item}
                  <CancelOutlinedIcon
                    className="clear-filter ms-2"
                    fontSize="small"
                    onClick={() => cancelFilter(index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div style={{ height: "calc(75vh - 120px)", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns.filter(
                (col) => !hiddenColumns.includes(col.field)
              )}
              checkboxSelection={props.checkBox}
              hideFooter={true}
              rowHeight={40}
            />
            <div className="custom-table-footer">
              <Row>
                <Col md={5}>
                  <form className="table-footer-left">
                    <span>Show </span>
                    <label>
                      <select
                        className="form-select"
                        defaultValue={perPage}
                        onChange={(event) => applyDataLength(event)}
                      >
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </label>
                    <span> of {totalRow} total entries</span>
                  </form>
                </Col>
                <Col md={7}>
                  <div className="table-footer-right">
                    <Stack spacing={2}>
                      <Pagination
                        count={lastPage}
                        page={activePage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                      />
                    </Stack>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress className="loading" style={{ margin: "auto" }} />
          </div>
        </div>
      )}
    </div>
  );
}
export default MarketplaceTable;
