import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";
import { Row, Col } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Moment from "moment";
import ConditionFilter from "../../../components/Filters/condition-filter";
import load from "../../../assets/images/icons/Spinner.gif";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import ExportIcon from "../../../assets/images/icons/export.svg";
import axios from "axios";

let rows: any = [];
let columns: GridColDef[] = [];

function ProductsTable(props) {
  let apiEndPoint =
    "https://captensbiapi.agilensmartservices.com/Ads/SDCampaignManager/Products/All";
  // "https://adsautomationapi.agilensmartservices.com/Ads/SPCampaignManager/" +
  // props.tabName;\
  console.log(props.tabName);
  let pageload = true;
  let currPage = 1;
  let dataLength = 50;
  const [gridHeight, setGridHeight] = useState(400);
  const [metaData, setMetaData] = useState<any>([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedRowid, setSelectedRows] = useState<any>([]);
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [filterDateRange, setFilterDateRange] = useState<any[]>([]);
  const [dropdownDatas, setDropdownDatas] = useState<any[]>([]);
  const [checked, setChecked] = useState(false);

  let filterData: any = [];
  const [spinner, setSpinner] = useState(false);
  const [globalFilterFromData, setGlobalFilterFromData] = useState<any[]>([]);
  const [searchKeyFilter, setSearchKeyFilter] = useState("");

  // aaa
  const [columFiltervalue, setColumFiltervalue] = useState<any[]>([]);
  const [dropdownDataFilter, SetDropdownDataFilter] = useState<any[]>([]);

  const [searchKeyValue, setSearchKeyValue] = useState<any[]>([]);

  let searchKey = "";

  useEffect(() => {
    setMetaData(props.metaData);
  }, [props.metaData, metaData]);
  useEffect(() => {
    if (props.filterDateRange) {
      if (props.filterDateRange.length > 0) {
        if (filterDateRange !== props.filterDateRange) {
          setFilterDateRange(props.filterDateRange);
          pageload = true;
          fetchData();
          pageload = false;
        }
      }
    }

    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const newHeight = Math.floor(windowHeight * 0.6);
      setGridHeight(newHeight);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Initial call to set the height
    handleResize();
  });
  useEffect(() => {
    if (props.tabName && pageload) {
      fetchData();
      pageload = false;
    }
  }, []);

  useEffect(() => {
    setApiLoading(true);
    if (props.condition && props.selectedProfiles && props.filterDateRange) {
      if (filterDateRange !== props.filterDateRange) {
        setFilterDateRange(props.filterDateRange);
      }

      columns = [];
      fetchData();
    }
  }, [props.condition, props.selectedProfiles, props.filterDateRange]);
  const fetchData = async () => {
    setApiLoading(true);
    let apiEndPoint;

    if (props.selectedProfiles === "SP") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/${props.tabName}/GetAll`;
    } else if (props.selectedProfiles === "SB") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SBCampaignManager/${props.tabName}/All`;
    } else if (props.selectedProfiles === "SD") {
      apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SDCampaignManager/${props.tabName}/All`;
    }

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = apiEndPoint;
    let advancedFilters = [];
    let advancedFilterDateRange = {};

    if (filterData.length > 0) {
      advancedFilters = filterData;
    }
    if (props.filterDateRange.length > 0) {
      advancedFilterDateRange = {
        dateRange: "Custom",
        startDate:
          Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
        endDate:
          Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
      };
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        profileId: Number(props.condition),
        pageNumber: currPage,
        pageSize: dataLength,
        globalFilters: {
          searchText: searchKey,
          advancedFilters: advancedFilters,
          dateRanges: advancedFilterDateRange,
        },
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      const result = responseData.result;

      setTotalRow(result.filteredCount);
      setPerPage(result.perPage);
      setActivePage(result.currPage);
      setLastPage(result.lastPage);
      const headers = result.headers;

      // Filter out the "Marketplace" column
      const filteredHeaders = headers.filter(
        (header) => header.keyName !== "marketplace"
      );

      setDropdownDatas(filteredHeaders);
      SetDropdownDataFilter(filteredHeaders);

      if (columns.length < 1) {
        for (let i = 0; i < filteredHeaders.length; i++) {
          if (filteredHeaders[i].keyName === "status") {
            columns.push({
              field: filteredHeaders[i].keyName,
              headerName: filteredHeaders[i].displayName,
              width: 100,
              renderCell: (params) => {
                return (
                  <i className={`status ${params.row.campaignStatus}`}></i>
                );
              },
              description: filteredHeaders[i].fullName,
            });
          } else if (filteredHeaders[i].keyName === "campaignName") {
            columns.push({
              field: filteredHeaders[i].keyName,
              headerName: filteredHeaders[i].displayName,
              minWidth: 150,
              flex: 0.5,
              description: filteredHeaders[i].fullName,
            });
          } else {
            columns.push({
              field: filteredHeaders[i].keyName,
              headerName: filteredHeaders[i].displayName,
              minWidth: 120,
              flex: 0.5,
              description: filteredHeaders[i].fullName,
            });
          }
        }
      }

      rows = result.data;
      setApiLoading(false);
      console.log("API response data:", result.data);
    } catch (error) {
      setApiLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    dataLength = parseInt(e.target.value);
    fetchData();
  };

  const handleCallback = (childData) => {
    setGlobalFilterFromData(childData);
    filterData = childData;
    fetchData();
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      searchKey = event.target.value;
      setSearchKeyFilter(event.target.value);
      fetchData();
      event.preventDefault();
    }
  };
  const cancelDropdown = () => {
    const eleRm: any = document.getElementsByClassName("dropdown-menu");
    for (let i = 0; i < eleRm.length; i++) {
      eleRm[i].classList.remove("show");
    }
  };

  // aaa hide data
  const hiddenColumns = columFiltervalue;
  // console.log("hide column : " + hiddenColumns);

  const applyColumns = () => {
    console.log("12345678 apply columns : " + searchKeyValue);
    // aaa
    setColumFiltervalue(searchKeyValue);
  };

  const handleChange = (event, value: number) => {
    currPage = value;
    // fetchData();
  };

  //
  let userToken = localStorage.getItem("userToken");
  let AuthToken = "Bearer " + userToken;

  const handleExportData = async () => {
    setSpinner(true);

    try {
      let userToken = localStorage.getItem("userToken");
      if (!userToken) {
        throw new Error("User token not found");
      }

      let AuthToken = "Bearer " + userToken;
      let advancedFilters: any = [];
      let advancedFilterDateRange: any = {};

      if (globalFilterFromData.length > 0) {
        advancedFilters = globalFilterFromData;
      }

      if (props.filterDateRange.length > 0) {
        advancedFilterDateRange = {
          dateRange: "Custom",
          startDate:
            Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
          endDate:
            Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
            "T13:32:30.064Z",
        };
      }

      let apiEndPoint = "";

      switch (props.selectedProfiles) {
        case "SP":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SPCampaignManager/${props.tabName}/Export`;

          break;
        case "SB":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SBCampaignManager/${props.tabName}/Export`;
          break;
        case "SD":
          apiEndPoint = `https://captensbiapi.agilensmartservices.com/Ads/SDCampaignManager/${props.tabName}/Export`;

          break;
        default:
          throw new Error("Invalid profile selected");
      }

      const response = await fetch(apiEndPoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          profileId: Number(props.condition),
          pageNumber: currPage,
          pageSize: dataLength,
          globalFilters: {
            searchText: searchKeyFilter,
            advancedFilters: advancedFilters,
            dateRanges: advancedFilterDateRange,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to export data");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      let profileDescription;
      switch (props.selectedProfiles) {
        case "SP":
          profileDescription = "Sponsored Product";
          break;
        case "SB":
          profileDescription = "Sponsored Brand";
          break;
        case "SD":
          profileDescription = "Sponsored Display";
          break;
        default:
          throw new Error("Invalid profile selected");
      }

      const storedLabel = localStorage.getItem("selectedAccountLabel");
      const country = localStorage.getItem("Country");

      console.log("Stored Label:", storedLabel);

      a.download = `${storedLabel}${country}_${profileDescription}_${
        props.tabName
      }_${advancedFilterDateRange.startDate.slice(
        0,
        10
      )}-${advancedFilterDateRange.endDate.slice(0, 10)}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // Handle success
      console.log("Export successful");
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      toast("Export failed");
      console.error("Error exporting data:", error);
    }
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <Row className="mt-2 mb-2">
        <Col>
          <div className="filter-container">
            <Row>
              <Col md={3} className="padding-lr-10"></Col>
              <Col md={9}>
                <div>
                  <form>
                    <div className="search-filter-container">
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        placeholder="Search"
                        id="globalSearch"
                        name="globalSearch"
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </form>
                </div>
                <div className="filter-item filter-link-container dropdownContent">
                  <p
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    <i>
                      <img src={FilterIcon} alt="filter icon" />
                    </i>
                    <span>Filter</span>
                    <i
                      className="fa fa-angle-down down-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <div
                    className="dropdown-menu dropdown-menu-lg-end"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <ConditionFilter
                      parentCallback={handleCallback}
                      dropdownData={dropdownDatas}
                      metaData={metaData}
                    />
                  </div>
                </div>
                {!spinner ? (
                  <div className="filter-item export-link-container">
                    <p>
                      <i>
                        <img src={ExportIcon} alt="filter icon" />
                      </i>
                      <span onClick={handleExportData}>Export</span>
                    </p>
                  </div>
                ) : (
                  <div className="filter-item export-link-container">
                    <img src={load} height={40} width={40} alt="spinner" />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {!apiLoading ? (
        <div style={{ height: gridHeight, width: "100%" }}>
          <DataGrid
            rows={rows}
            //columns={columns}
            // aaa
            columns={columns.filter(
              (col) => !hiddenColumns.includes(col.field)
            )}
            // checkboxSelection={props.checkBox}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = rows.filter((row: any) =>
                selectedIDs.has(row.id)
              );
              console.log("idssss" + ids);

              setSelectedRows(ids);
              console.log("selected" + selectedRowid);
            }}
            hideFooter={true}
            rowHeight={40}
          />
          <div className="custom-table-footer">
            <Row>
              <Col md={5}>
                <form className="table-footer-left">
                  <span>Show </span>
                  <label>
                    <select
                      className="form-select"
                      defaultValue={perPage}
                      onChange={(event) => applyDataLength(event)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                  </label>
                  <span> of {totalRow} total entries</span>
                </form>
              </Col>
              <Col md={7}>
                <div className="table-footer-right">
                  <Stack spacing={2}>
                    <Pagination
                      count={lastPage}
                      page={activePage}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChange}
                    />
                  </Stack>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress className="loading" style={{ margin: "auto" }} />
          </div>
        </div>
      )}
    </div>
  );
}
export default ProductsTable;
